import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Card, Heading, InternalLink } from '@pafcloud/base-components';
import { Breakpoint, Color, Font, FontTextSize } from '@pafcloud/style';

export const BettingCard = styled(Card)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  color: Color.BodyText,
});

export const LiveIcon = styled.span({
  alignSelf: 'flex-start',
  marginLeft: 8,
  padding: '4px 6px',
  border: `1px solid ${Color.Surface.Base.Dimmed}`,
  borderRadius: Color.CornerRadius.Small,
  color: Color.BodyText,
  fontFamily: 'arial, sans-serif',
  fontWeight: 'bold',
  fontStyle: 'italic',
  fontSize: '12px',
  whiteSpace: 'nowrap',

  '::before': {
    content: '""',
    display: 'inline-block',
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: '50%',
    background: 'red',
  },
});

export const EventStart = styled.time({
  textAlign: 'right',

  [Breakpoint.Phone]: {
    width: '30%',
  },
});

export const EventLink = styled(InternalLink)({
  display: 'block',
  overflow: 'hidden',
  padding: 16,
});

export const EventHeader = styled.header({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 24,
});

export const EventTitle = styled(Heading)({
  margin: 0,
  fontSize: FontTextSize.Big,
  fontWeight: 'bold',
  color: Color.HighlightedText,

  [Breakpoint.BigScreenOrLarger]: {
    fontSize: FontTextSize.Huge,
  },
});

export const EventSubTitle = styled.div({
  color: Color.MutedText,
  fontSize: FontTextSize.Small,
});

export const EventStartTime = styled(EventTitle)().withComponent('div');

export const EventStartDay = styled(EventSubTitle)();

export const Participant = styled.div({
  margin: '8px 0px',
  display: 'flex',
  alignItems: 'center',
  fontSize: FontTextSize.Normal,
  color: Color.BodyText,
  gap: 8,
  flexFlow: 'row wrap',

  [Breakpoint.BigScreenOrLarger]: {
    fontSize: FontTextSize.Big,
  },
});

export const ParticipantName = styled.div({
  flex: '1 0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '10ch',
});

export const MatchStats = styled.div({
  display: 'flex',
  flex: '1 0',
  justifyContent: 'flex-end',
});

const SharedScoreStyles = css({
  boxSizing: 'content-box',
  minWidth: '2ch',
  padding: '4px 8px',
  fontWeight: 900,
  fontFamily: Font.HeadingAlt,
  textAlign: 'center',

  // Ensures same width for all numbers
  fontVariantNumeric: 'tabular-nums',
  // Fixes for 'AD' in tennis
  fontVariantCaps: 'all-small-caps',
});

export const MatchSetPoints = styled.div(SharedScoreStyles, {
  paddingInline: 'clamp(1px, .5ch, 8px)',

  '& + &': {
    borderLeft: `1px solid ${Color.Surface.Base.Dimmed}`,
  },
});

export const MatchScore = styled.div(SharedScoreStyles, {
  minWidth: '3ch',
  marginLeft: '.5ch',
  borderRadius: Color.CornerRadius.Small,
  boxShadow: 'inset 0 0 0 1px #333',
  // Note: MatchScore has inverted color compared to the card. This is why we use Foreground as background.
  background: Color.Surface.Base.Foreground,
  color: Color.Surface.Base.Background,
});

export const Outcomes = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'stretch',
  width: '100%',
  marginTop: 'auto',
  borderTop: `1px solid ${Color.Surface.Base.Dimmed}`,
});

export const OutcomeLabel = styled.span({
  color: Color.MutedText,
  marginRight: 8,
});

export const OutcomeOdds = styled.strong();

export const OutcomeButton = styled(InternalLink)({
  position: 'relative',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `12px min(16px, 5%)`,
  fontSize: FontTextSize.Normal,
  fontFamily: Font.Button,
  color: Color.BodyText,

  ':hover, :focus': {
    outline: 'none',
    color: Color.LinkText,
  },

  // TODO
  '&[aria-disabled=true]': {
    pointerEvents: 'none',

    [`${OutcomeOdds}`]: {
      color: Color.MutedText,
      textDecoration: 'line-through',
    },
  },

  '& + &': {
    borderLeft: `1px solid ${Color.Surface.Base.Dimmed}`,
  },
});
